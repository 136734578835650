<template>
  <div class="login-bg">
    <div class="login-box">
      <div class="title">广告管理平台</div>
      <div class="ai-wrap"></div>
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="rules"
        status-icon
        @keyup.enter.native="login"
      >
        <el-form-item prop="userName">
          <el-input
            v-model="loginForm.userName"
            placeholder="用户名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            type="password"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="verCode">
          <el-col :span="15"
            ><el-input
              v-model="loginForm.verCode"
              placeholder="验证码"
            ></el-input
          ></el-col>
          <el-col :span="8" :offset="1" style="line-height: 0"
            ><el-image :src="verCodeUrl" @click="initVerCode"></el-image
          ></el-col>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="login-button"
            :loading="loading"
            @click="login"
            >登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        userName: null,
        password: null,
        verCode: null,
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        verCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      verCodeUrl: "",
      loading: false,
    };
  },
  mounted() {
    this.initVerCode();
  },
  methods: {
    initVerCode() {
      this.verCodeUrl = "/system/verCode/get?ts=" + new Date().getTime();
    },
    login() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let loginData = {
            userName: this.loginForm.userName,
            password: this.$encodePassword(this.loginForm.password),
            verCode: this.loginForm.verCode,
          };
          this.$http
            .post("/system/login", loginData)
            .then((response) => {
              if (response.data.code === 200) {
                this.$store.commit("setUserAuth", response.data.data);
                this.$message({
                  message: "登录成功",
                  type: "success",
                  duration: 1000,
                  onClose: () => {
                    this.$router.push({ name: "LandingList" });
                  },
                });
              }
            })
            .catch(() => {
              this.loginForm.verCode = "";
              this.initVerCode();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    getBroswerAndVersion() {
      var os = navigator.platform;
      var userAgent = navigator.userAgent;
      var info = "";
      var tempArray = "";
      //判断浏览器版本
      var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
      var isIE =
        userAgent.indexOf("compatible") > -1 &&
        userAgent.indexOf("MSIE") > -1 &&
        !isOpera; //判断是否IE浏览器
      var isEdge = userAgent.toLowerCase().indexOf("edge") > -1 && !isIE; //判断是否IE的Edge浏览器
      var isIE11 =
        userAgent.toLowerCase().indexOf("trident") > -1 &&
        userAgent.indexOf("rv") > -1;

      if (/[Ff]irefox(\/\d+\.\d+)/.test(userAgent)) {
        tempArray = /([Ff]irefox)\/(\d+\.\d+)/.exec(userAgent);
        info += tempArray[1] + tempArray[2];
      } else if (isIE) {
        var version = "";
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
          version = "IE7";
        } else if (fIEVersion == 8) {
          version = "IE8";
        } else if (fIEVersion == 9) {
          version = "IE9";
        } else if (fIEVersion == 10) {
          version = "IE10";
        } else {
          version = "0";
        }

        info += version;
      } else if (isEdge) {
        info += "Edge";
      } else if (isIE11) {
        info += "IE11";
      } else if (/[Cc]hrome\/\d+/.test(userAgent)) {
        tempArray = /([Cc]hrome)\/(\d+)/.exec(userAgent);
        info += tempArray[1] + tempArray[2];
      } else if (
        /[Vv]ersion\/\d+\.\d+\.\d+(\.\d)* *[Ss]afari/.test(userAgent)
      ) {
        tempArray = /[Vv]ersion\/(\d+\.\d+\.\d+)(\.\d)* *([Ss]afari)/.exec(
          userAgent
        );
        info += tempArray[3] + tempArray[1];
      } else if (/[Oo]pera.+[Vv]ersion\/\d+\.\d+/.test(userAgent)) {
        tempArray = /([Oo]pera).+[Vv]ersion\/(\d+)\.\d+/.exec(userAgent);
        info += tempArray[1] + tempArray[2];
      } else {
        info += "unknown";
      }
      return info;
    },
  },
};
</script>

<style scoped>
.login-bg {
  width: 100vw;
  height: 100vh;
  background-image: url(../assets/bg.png);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-box {
  width: 380px;
  height: 370px;
  padding: 20px 30px;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
}
.title {
  background: #409eff;
  margin: 0 0 0 -48px;
  padding: 16px 35px;
  color: #fff;
  font-size: 16px;
}
.ai-wrap {
  width: 18px;
  height: 10px;
  margin: 0 0 22px -48px;
  background: url(../assets/aiwrap.png);
}
.el-image {
  height: 40px;
  cursor: pointer;
}
.login-button {
  width: 100%;
}
</style>
